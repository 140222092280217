<template>
  <div class="md-layout admins-wrap">
    <div
      class="
        admins
        md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100
      "
    >
      <div class="admins-navbar">
        <md-tabs>
          <md-tab
            v-for="(name, key) in tabs"
            :key="name"
            :md-label="name"
            @click="handleChange(key)"
          />
        </md-tabs>
      </div>
    </div>
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-content>
            <ReturnOrders
              :orders="sortOrders"
              :meta="meta"
              @setOrder="item => SET_ORDER(item)"
            />
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import ReturnOrders from "@/components/Tables/ReturnOrders.vue";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "List",
  components: {
    ReturnOrders,
  },

  data() {
    return {
      tabs: {
        "requested": "Запрошен возврат",
        "approved": "Возврат одобрен",
        "rejected": "Возврат отклонен",
        "paid": "Выплачен"
      },
      activeTab: "requested"
    };
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);

    await this.getListOrders("requested");

    this.$store.commit("SET_SHOW_LOADER", false);
  },
  computed: {
    ...mapState("returnPage", ["orders", "meta"]),
    sortOrders() {
      return this.orders.filter(order => order.status == this.activeTab);
    },
  },
  methods: {
    ...mapActions("returnPage", ["getListOrders"]),
    ...mapMutations("returnPage", ["SET_ORDER"]),
    async handleChange(id) {
      this.activeTab = id;
      this.$store.commit("SET_SHOW_LOADER", true);

      await this.getListOrders(id);

      this.$store.commit("SET_SHOW_LOADER", false);
    },
  },
};
</script>

<style lang="scss" scoped>

.admins-wrap {
  position: relative;
  z-index: 2;
}

.admins-navbar {
  margin-bottom: 20px;

  &::v-deep {
    .md-tabs-navigation {
      background-color: #58b05c !important;
      padding: 20px;
    }
  }
}

.md-layout {
  max-width: 100%;
}

.search-wrap {
  display: flex;
  justify-content: space-between;

  & > *:not(:last-child) {
    margin-right: 8px;
  }

  & > *:not(:first-child) {
    margin-left: 8px;
  }

  .md-button {
    width: 20%;
    height: max-content;
    flex-shrink: 0;
    align-self: center;
  }

  .md-field {
    .md-input,
    label {
      color: white !important;
      -webkit-text-fill-color: white !important;
    }

    &::v-deep {
      .md-input {
        color: white !important;
        -webkit-text-fill-color: white !important;
      }

      .md-clear {
        background: transparent !important;
        box-shadow: none;
        top: 18px;

        i > svg {
          fill: white !important;
        }
      }
    }
  }
}
</style>
