<template>
  <div>
    <div v-if="orders && orders.length" class="position-table">
      <md-table
          :value="orders"
      >
        <md-table-row
            slot="md-table-row"
            slot-scope="{ item }"
            @click="openOrder(item)"
        >
          <md-table-cell md-label="ID" md-sort-by="id" class="custom-field">
            <span>{{ item.id }}</span>
          </md-table-cell>

          <md-table-cell md-label="Сумма заказа" md-sort-by="name" class="custom-field">
            <span>{{ sumOrders(item.order.order_product) }}</span>
          </md-table-cell>

          <md-table-cell
              md-label="Сумма возврата"
              class="custom-field"
          >
            <span>{{ sumOrders(item.order_return_products) }}</span>
          </md-table-cell>

          <md-table-cell
              md-label="Дата заказа"
              class="custom-field"
          >
            <span>{{ formatISODate(item.order.created_at)}}</span>
          </md-table-cell>

          <md-table-cell md-label="Дата запроса на возврат" class="custom-field">
            <span>{{ formatISODate(item.order.updated_at) }}</span>
          </md-table-cell>

          <md-table-cell
              md-label="Статус"
              class="custom-field"
          >
            <span>{{ item.text_status }}</span>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <div v-if="meta.last_page > 1" class="pagination">
        <md-button
            class="md-primary"
            :disabled="meta.current_page === 1"
            @click="$emit('prev')"
        >
          <md-icon>navigate_before</md-icon>
        </md-button>
        <div class="pages">
          <span>{{ meta.current_page }}</span>
          /
          <span>{{ meta.last_page }}</span>
        </div>
        <md-button
            class="md-primary"
            :disabled="meta.current_page === meta.last_page"
            @click="$emit('next')"
        >
          <md-icon>navigate_next</md-icon>
        </md-button>
      </div>
    </div>
    <div v-else class="my-empty empty">Пусто</div>
  </div>
</template>

<script>
import { formatISODate } from "@/helpers/formatDate";

export default {
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
    meta: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      customSortBy: "id",
      customSortOrder: "asc",
    };
  },


  methods: {
    formatISODate,
    sumOrders(productArray) {
      return productArray.reduce((prev, next) => {
        return prev + +next.price
      }, 0);
    },

    customSort(value) {
      this.$emit("sort", {
        sortBy: this.customSortBy,
        sortOrder: this.customSortOrder,
      });

      return value;
    },
    openOrder(order) {
      this.$router.push(`/return_page/${order.id}`);
      this.$emit('setOrder', order);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;

  .pages {
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
}

::v-deep {
  .md-table-sortable-icon {
    left: unset !important;
    right: 8px !important;
  }

  .md-table-row {
    cursor: pointer;
  }
}
</style>
